import axios from 'axios';
import { CONFIG } from '../config';

const API_BASE_URL = CONFIG.API_URL;
const TOKEN_KEY = 'token';

// Create axios instance
const api = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

export const authUtils = {
  setToken: (token) => {
    localStorage.setItem(TOKEN_KEY, token);
  },
  getToken: () => {
    return localStorage.getItem(TOKEN_KEY);
  },
  removeToken: () => {
    localStorage.removeItem(TOKEN_KEY);
  },
  isAuthenticated: () => {
    return !!localStorage.getItem(TOKEN_KEY);
  }
};

// Add token to requests
api.interceptors.request.use(
  (config) => {
    const token = authUtils.getToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Handle response errors
api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status === 401) {
      authUtils.removeToken();
      window.location.href = '/login';
    }
    return Promise.reject(error);
  }
);

export const authAPI = {
  login: async (credentials) => {
    try {
      const response = await api.post('/Login/login', credentials);
      if (response.data.token) {
        authUtils.setToken(response.data.token);
      }
      return response.data;
    } catch (error) {
      console.error('Login error:', error);
      throw error.response?.data || { general: 'Login failed' };
    }
  },

  getCurrentUser: async () => {
    try {
      const response = await fetch(`${CONFIG.API_URL}/User/current`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${authUtils.getToken()}`,
          'Content-Type': 'application/json'
        },
        credentials: 'include'
      });

      if (!response.ok) {
        throw new Error('Failed to fetch user data');
      }

      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error getting user data:', error);
      throw error;
    }
  },

  logout: () => {
    authUtils.removeToken();
    window.location.href = '/login';
  }
};