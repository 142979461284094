import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { authUtils } from '../services/api';
import "../css/Header.css";

function Header() {
  const navigate = useNavigate();
  const location = useLocation();
  const isAuthenticated = authUtils.isAuthenticated();
  const isDashboard = location.pathname === '/dashboard';

  const handleLogout = () => {
    authUtils.removeToken();
    navigate('/login');
  };

  return (
    <header className="header">
      <div className="header-content">
        <h1 onClick={() => navigate('/')} style={{ cursor: 'pointer' }}>
          Bolt.2
        </h1>
        <div className="header-buttons">
          {isAuthenticated ? (
            <>
              {isDashboard ? (
                <button 
                  className="header-button home-button"
                  onClick={() => navigate('/')}
                >
                  Home
                </button>
              ) : (
                <button 
                  className="header-button dashboard-button"
                  onClick={() => navigate('/dashboard')}
                >
                  Dashboard
                </button>
              )}
              <button 
                className="header-button logout-button" 
                onClick={handleLogout}
              >
                Logout
              </button>
            </>
          ) : (
            <>
              <button 
                className="header-button" 
                onClick={() => navigate('/login')}
              >
                Login
              </button>
              <button 
                className="header-button" 
                onClick={() => navigate('/register')}
              >
                Register
              </button>
            </>
          )}
        </div>
      </div>
    </header>
  );
}

export default Header;