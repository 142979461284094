import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { authAPI, authUtils } from "../services/api";
import { Search, Loader, Image, Camera } from 'lucide-react';
import ProductCard from '../Components/ProductCard';
import "../css/Home.css";
import Webcam from "react-webcam";
import { CONFIG } from '../config';

function Home() {
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Search states
  const [query, setQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [searchLoading, setSearchLoading] = useState(false);
  const [searchError, setSearchError] = useState(null);
  const [searchPerformed, setSearchPerformed] = useState(false);
  
  // Toggle state for search type - set to true for Image Search default
  const [isImageSearch, setIsImageSearch] = useState(true);
  
  // Camera states
  const webcamRef = useRef(null);
  const [cameraError, setCameraError] = useState(null);

  useEffect(() => {
  const fetchUserInfo = async () => {
    try {
      if (!authUtils.isAuthenticated()) {
        navigate('/login');
        return;
      }

      setLoading(true);
      const response = await fetch(`${CONFIG.API_URL}/User/current`, {
        headers: {
          'Authorization': `Bearer ${authUtils.getToken()}`,
          'Content-Type': 'application/json'
        }
      });

      if (!response.ok) {
        throw new Error('Failed to fetch user data');
      }

      const data = await response.json();
      setUserInfo(data);
      setError(null);
    } catch (err) {
      console.error('Error fetching user info:', err);
      setError('Failed to load user information');
      if (err.response?.status === 401) {
        authUtils.removeToken();
        navigate('/login');
      }
    } finally {
      setLoading(false);
    }
  };

  fetchUserInfo();
}, [navigate]);

  const toggleSearchType = () => {
    setIsImageSearch(!isImageSearch);
    setSearchResults([]);
    setSearchError(null);
    setSearchPerformed(false);
    setQuery('');
    setCameraError(null);
  };

  const handleSearchChange = (e) => {
    setQuery(e.target.value);
    if (searchError) setSearchError(null);
  };

  const handleSearch = async (e) => {
    e.preventDefault();
    if (!query.trim()) return;

    setSearchLoading(true);
    setSearchError(null);
    setSearchPerformed(true);

    try {
      const response = await fetch(
        `https://www.googleapis.com/customsearch/v1?q=${encodeURIComponent(query.trim())}&key=${process.env.REACT_APP_GOOGLE_API_KEY}&cx=${process.env.REACT_APP_GOOGLE_CSE_ID}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      const data = await response.json();
      
      if (!response.ok) {
        throw new Error(data.error?.message || 'Search failed');
      }

      const products = data.items.map((item) => ({
        title: item.title,
        link: item.link,
        price: 'Price not available',
        rating: 'Rating not available',
        snippet: item.snippet,
        image: item.pagemap?.cse_image ? item.pagemap.cse_image[0].src : null,
      }));

      setSearchResults(products);
    } catch (err) {
      setSearchError(err.message);
      setSearchResults([]);
    } finally {
      setSearchLoading(false);
    }
  };

  const captureImage = async () => {
    const imageSrc = webcamRef.current.getScreenshot();
    if (!imageSrc) {
      setSearchError('Failed to capture image');
      return;
    }

    try {
      // Convert base64 to blob
      const res = await fetch(imageSrc);
      const blob = await res.blob();
      
      // Create File object
      const file = new File([blob], "webcam-capture.jpg", { type: "image/jpeg" });
      
      // Create FormData
      const formData = new FormData();
      formData.append('image_file', file);

      // Process image
      await uploadAndProcessImage(formData);
    } catch (err) {
      setSearchError('Failed to process captured image');
      console.error('Capture error:', err);
    }
  };

  const uploadAndProcessImage = async (formData) => {
    setSearchLoading(true);
    setSearchError(null);
    setSearchPerformed(true);

    try {
      // Upload the image
      const uploadResponse = await fetch(`${CONFIG.API_URL}/Upload_Image/upload`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
        body: formData,
        credentials: 'include'
      });

      if (!uploadResponse.ok) {
        const uploadData = await uploadResponse.text();
        throw new Error(uploadData || 'Failed to upload image');
      }

      // Poll for results
      let attempt = 0;
      const maxAttempts = 10;
      
      while (attempt < maxAttempts) {
        await new Promise(resolve => setTimeout(resolve, 1000));
        
        const searchResponse = await fetch(`${CONFIG.API_URL}/Product_Search/search`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
          credentials: 'include'
        });

        const searchData = await searchResponse.json();

        if (searchResponse.ok && !searchData.error && !searchData.general) {
          setSearchResults(searchData.products || []);
          break;
        }

        if (searchData.error || searchData.general) {
          if (searchData.general !== 'Error retrieving picture description') {
            throw new Error(searchData.general || searchData.error || 'Failed to search products');
          }
        }

        attempt++;
        
        if (attempt === maxAttempts) {
          throw new Error('Timeout waiting for image processing. Please try again.');
        }
      }
    } catch (err) {
      console.error('Error during image search:', err);
      setSearchError(err.message);
      setSearchResults([]);
    } finally {
      setSearchLoading(false);
    }
  };

  const renderUserInfo = () => {
    if (loading) {
      return (
        <div className="user-info-container loading">
          <h3>User Information</h3>
          <div className="info-item">
            <span className="info-label">Loading...</span>
          </div>
        </div>
      );
    }

    if (error) {
      return (
        <div className="error-message">
          {error}
        </div>
      );
    }

    return (
      <div className="user-info-container">
        <h3>User Information</h3>
        {userInfo && (
          <>
            <div className="info-item">
              <span className="info-label">Username:</span>
              <span className="info-value">{userInfo.username}</span>
            </div>
            <div className="info-item">
              <span className="info-label">Email:</span>
              <span className="info-value">{userInfo.email}</span>
            </div>
            {userInfo.name && (
              <div className="info-item">
                <span className="info-label">Name:</span>
                <span className="info-value">{userInfo.name}</span>
              </div>
            )}
          </>
        )}
      </div>
    );
  };

  const renderSearchInput = () => {
    if (isImageSearch) {
      return (
        <div className="image-search-container">
          {cameraError ? (
            <div className="camera-error">
              <p>{cameraError}</p>
              <button 
                onClick={() => setCameraError(null)}
                className="retry-button"
              >
                Try Again
              </button>
            </div>
          ) : (
            <>
              <Webcam
                audio={false}
                ref={webcamRef}
                screenshotFormat="image/jpeg"
                className="webcam-view"
                onUserMediaError={(err) => {
                  console.error('Webcam error:', err);
                  setCameraError('Unable to access camera. Please check permissions.');
                }}
                videoConstraints={{
                  width: 720,
                  height: 720,
                  facingMode: "user"
                }}
              />
              <button
                onClick={captureImage}
                className="capture-button"
                disabled={searchLoading}
              >
                {searchLoading ? (
                  <Loader className="animate-spin" size={24} />
                ) : (
                  <Camera size={24} />
                )}
                <span>Capture Image</span>
              </button>
            </>
          )}
        </div>
      );
    }

    return (
      <form onSubmit={handleSearch} className="search-form">
        <div className="search-input-container">
          <input
            type="text"
            className="search-input"
            placeholder="Search for products..."
            value={query}
            onChange={handleSearchChange}
            disabled={searchLoading}
          />
          <button 
            type="submit" 
            className="search-button"
            disabled={searchLoading || !query.trim()}
          >
            {searchLoading ? (
              <Loader className="animate-spin" size={20} />
            ) : (
              <Search size={20} />
            )}
          </button>
        </div>
      </form>
    );
  };

  const renderSearchResults = () => {
    if (!searchPerformed && !searchLoading) {
      return (
        <div className="search-placeholder">
          <Search size={48} className="search-icon" />
          <p>{isImageSearch ? 'Take a picture to search' : 'Search for products to get started'}</p>
        </div>
      );
    }

    if (searchLoading) {
      return (
        <div className="search-loading">
          <Loader className="animate-spin" size={32} />
          <p>{isImageSearch ? 'Processing image...' : `Searching for "${query}"...`}</p>
        </div>
      );
    }

    if (searchError) {
      return (
        <div className="search-error">
          <p>{searchError}</p>
          <button 
            onClick={isImageSearch ? captureImage : handleSearch} 
            className="retry-button"
          >
            Try Again
          </button>
        </div>
      );
    }

    if (searchResults.length === 0 && searchPerformed) {
      return (
        <div className="no-results">
          <p>No products found{!isImageSearch && ` for "${query}"`}</p>
          <p className="suggestion">
            {isImageSearch ? 'Try taking another picture' : 'Try different keywords or check your spelling'}
          </p>
        </div>
      );
    }

    return (
      <div className="search-results">
        {searchResults.map((product, index) => (
          <ProductCard 
            key={`${product.link}-${index}`} 
            product={product} 
          />
        ))}
      </div>
    );
  };

  return (
    <div className="home-container">
      <div className="top-section">
        <div className="info-containers-wrapper">
          {renderUserInfo()}
        </div>

        <div className="search-container">
          <div className="toggle-switch-container">
            <div 
              className={`toggle-switch ${isImageSearch ? 'image-active' : ''}`}
              onClick={toggleSearchType}
            >
              <div className="toggle-slider" />
              <span className="toggle-option text">Text Search</span>
              <span className="toggle-option image">Image Search</span>
            </div>
          </div>

          {renderSearchInput()}

          <div className="results-container">
            {renderSearchResults()}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;