import React from 'react';
import { Star, ExternalLink } from 'lucide-react';
import '../css/ProductCard.css';

function ProductCard({ product }) {
  return (
    <div className="product-card">
      <a href={product.link} target="_blank" rel="noopener noreferrer" className="product-link">
        <div className="product-image-wrapper">
          <div className="product-image-container">
            {product.image ? (
              <img
                src={product.image}
                alt={product.title}
                className="product-image"
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = 'https://via.placeholder.com/300x300.png?text=No+Image';
                }}
              />
            ) : (
              <div className="product-image-placeholder">
                No image available
              </div>
            )}
          </div>
        </div>

        <div className="product-content">
          <div className="product-header">
            <h3 className="product-title">{product.title}</h3>
            {product.source && (
              <div className="product-source">
                <span>{product.source}</span>
                <ExternalLink size={16} className="external-icon" />
              </div>
            )}
          </div>

          <div className="product-details">
            <div className="product-price-rating">
              <span className="product-price">{product.price}</span>
              {product.rating && (
                <div className="product-rating">
                  <Star className="star-icon" size={16} />
                  <span>{product.rating}</span>
                </div>
              )}
            </div>
          </div>

          {product.snippet && (
            <p className="product-description">{product.snippet}</p>
          )}
        </div>
      </a>
    </div>
  );
}

export default ProductCard; 