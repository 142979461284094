import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { authAPI } from '../services/api';
import { CONFIG } from '../config';
import SearchHistory from '../Components/SearchHistory';
import '../css/Dashboard.css';

function Dashboard() {
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState('overview');
  const [totalSearches, setTotalSearches] = useState(0);
  const [lastSearchTime, setLastSearchTime] = useState(null);

  const getTimeAgo = (date) => {
    if (!date) return '-';
    
    const seconds = Math.floor((new Date() - new Date(date)) / 1000);
    
    let interval = Math.floor(seconds / 31536000);
    if (interval >= 1) return `${interval} year${interval === 1 ? '' : 's'} ago`;
    
    interval = Math.floor(seconds / 2592000);
    if (interval >= 1) return `${interval} month${interval === 1 ? '' : 's'} ago`;
    
    interval = Math.floor(seconds / 86400);
    if (interval >= 1) return `${interval} day${interval === 1 ? '' : 's'} ago`;
    
    interval = Math.floor(seconds / 3600);
    if (interval >= 1) return `${interval} hour${interval === 1 ? '' : 's'} ago`;
    
    interval = Math.floor(seconds / 60);
    if (interval >= 1) return `${interval} minute${interval === 1 ? '' : 's'} ago`;
    
    if (seconds < 10) return 'just now';
    return `${Math.floor(seconds)} second${seconds === 1 ? '' : 's'} ago`;
  };

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const response = await authAPI.getCurrentUser();
        setUserInfo(response);
      } catch (err) {
        console.error('Error fetching user info:', err);
        setError(err.message || 'Failed to load user information');
        if (err.response?.status === 401) {
          navigate('/login');
        }
      } finally {
        setLoading(false);
      }
    };

    fetchUserInfo();
  }, [navigate]);

  const handleHistoryLoaded = (maxLogId, lastSearchDate) => {
    setTotalSearches(maxLogId);
    setLastSearchTime(lastSearchDate);
  };

  const renderOverview = () => (
    <div className="dashboard-overview">
      <div className="stats-grid">
        <div className="stat-card">
          <h3>Total Searches</h3>
          <p className="stat-value">{totalSearches || '-'}</p>
          <p className="stat-change positive">All time</p>
        </div>
        <div className="stat-card">
          <h3>Today's Searches</h3>
          <p className="stat-value">-</p>
          <p className="stat-change neutral">Current day</p>
        </div>
        <div className="stat-card">
          <h3>Last Search</h3>
          <p className="stat-value">{getTimeAgo(lastSearchTime)}</p>
          <p className="stat-change neutral">Time ago</p>
        </div>
        <div className="stat-card">
          <h3>Search Success Rate</h3>
          <p className="stat-value">-</p>
          <p className="stat-change neutral">Average</p>
        </div>
      </div>

      <SearchHistory onHistoryLoaded={handleHistoryLoaded} />
    </div>
  );

  const renderProfile = () => (
    <div className="dashboard-profile">
      {userInfo && (
        <>
          <div className="profile-header">
            <div className="profile-avatar">
              <img 
                src={`https://ui-avatars.com/api/?name=${encodeURIComponent(userInfo.name)}&background=random`} 
                alt="Profile" 
              />
            </div>
            <div className="profile-info">
              <h2>{userInfo.name}</h2>
              <p>{userInfo.email}</p>
            </div>
          </div>
          <div className="profile-details">
            <div className="detail-group">
              <h3>Account Information</h3>
              <div className="detail-item">
                <span>Username</span>
                <span>{userInfo.username}</span>
              </div>
              <div className="detail-item">
                <span>Member Since</span>
                <span>November 2024</span>
              </div>
              <div className="detail-item">
                <span>Status</span>
                <span className="status-active">Active</span>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );

  if (loading) {
    return <div className="dashboard-loading">Loading...</div>;
  }

  if (error) {
    return <div className="dashboard-error">{error}</div>;
  }

  return (
    <div className="dashboard-container">
      <div className="dashboard-sidebar">
        <div className="sidebar-header">
          <h2>Dashboard</h2>
        </div>
        <nav className="sidebar-nav">
          <button 
            className={`nav-button ${activeTab === 'overview' ? 'active' : ''}`}
            onClick={() => setActiveTab('overview')}
          >
            Overview
          </button>
          <button 
            className={`nav-button ${activeTab === 'profile' ? 'active' : ''}`}
            onClick={() => setActiveTab('profile')}
          >
            Profile
          </button>
          <button 
            className={`nav-button ${activeTab === 'settings' ? 'active' : ''}`}
            onClick={() => setActiveTab('settings')}
          >
            Settings
          </button>
        </nav>
      </div>
      <div className="dashboard-content">
        {activeTab === 'overview' && renderOverview()}
        {activeTab === 'profile' && renderProfile()}
        {activeTab === 'settings' && <div className="dashboard-settings">Settings content</div>}
      </div>
    </div>
  );
}

export default Dashboard;