import React, { useState, useEffect } from 'react';
import { CONFIG } from '../config';
import SearchActivityChart from './SearchActivityChart';

function SearchHistory({ onHistoryLoaded = () => {} }) {
  const [history, setHistory] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchSearchHistory();
  }, []);

  const fetchSearchHistory = async () => {
    try {
      const response = await fetch(`${CONFIG.API_URL}/Search_History/user_history_all`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json'
        }
      });

      const data = await response.json();
      
      if (response.ok) {
        const searchHistory = data.search_history || [];
        setHistory(searchHistory);
        
        if (searchHistory.length > 0) {
          const maxLogId = Math.max(...searchHistory.map(item => item.logId));
          const lastSearch = searchHistory.reduce((latest, current) => {
            return new Date(current.retrieval_date) > new Date(latest.retrieval_date) 
              ? current 
              : latest;
          }, searchHistory[0]);
          
          onHistoryLoaded(maxLogId, lastSearch.retrieval_date);
        }
      } else {
        throw new Error(data.general || 'Failed to fetch search history');
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleString('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  if (loading) {
    return <div className="dashboard-loading">Loading search history...</div>;
  }

  if (error) {
    return <div className="dashboard-error">{error}</div>;
  }

  return (
    <>
      <SearchActivityChart history={history} />
      <div className="recent-activity">
        <h3>Recent Searches</h3>
        <div className="activity-list">
          {history.length === 0 ? (
            <div className="activity-item">
              <span className="activity-type">No search history found</span>
            </div>
          ) : (
            history.map((item, index) => {
              const urls = JSON.parse(item.top_url_links);
              return (
                <div key={index} className="activity-item">
                  <div style={{ flex: 1 }}>
                    <div className="activity-type">{item.keywords}</div>
                    <div className="activity-duration">
                      {urls.map((url, urlIndex) => (
                        <a 
                          key={urlIndex}
                          href={url}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{
                            display: 'block',
                            color: '#a0a0a0',
                            fontSize: '0.875rem',
                            marginTop: '0.5rem',
                            textDecoration: 'none'
                          }}
                          onMouseOver={(e) => e.target.style.color = '#ffffff'}
                          onMouseOut={(e) => e.target.style.color = '#a0a0a0'}
                        >
                          {url.length > 50 ? url.substring(0, 50) + '...' : url}
                        </a>
                      ))}
                    </div>
                  </div>
                  <span className="activity-date">
                    {formatDate(item.retrieval_date)}
                  </span>
                </div>
              );
            })
          )}
        </div>
      </div>
    </>
  );
}

export default SearchHistory;