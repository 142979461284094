import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

function SearchActivityChart({ history = [] }) {
  // Process the history data to group searches by day
  const processData = () => {
    if (!Array.isArray(history) || history.length === 0) {
      return [];
    }

    try {
      const searchesByDay = history.reduce((acc, item) => {
        if (!item?.retrieval_date) {
          return acc;
        }
        const date = new Date(item.retrieval_date).toLocaleDateString();
        acc[date] = (acc[date] || 0) + 1;
        return acc;
      }, {});

      // Convert to array format for recharts and get last 7 days
      return Object.entries(searchesByDay)
        .map(([date, count]) => ({
          date,
          searches: count
        }))
        .sort((a, b) => new Date(a.date) - new Date(b.date))
        .slice(-7); // Last 7 days
    } catch (error) {
      console.error('Error processing chart data:', error);
      return [];
    }
  };

  const data = processData();

  if (data.length === 0) {
    return (
      <div className="stat-card" style={{ gridColumn: 'span 2', height: '300px' }}>
        <h3>Search Activity (Last 7 Days)</h3>
        <div style={{ 
          display: 'flex', 
          justifyContent: 'center', 
          alignItems: 'center', 
          height: 'calc(100% - 30px)',
          color: '#a0a0a0' 
        }}>
          No search history data available
        </div>
      </div>
    );
  }

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div style={{
          backgroundColor: '#2d2d2d',
          border: '1px solid #3d3d3d',
          borderRadius: '4px',
          padding: '10px',
        }}>
          <p style={{ color: '#ffffff', margin: '0' }}>{`Date: ${label}`}</p>
          <p style={{ color: '#10b981', margin: '5px 0 0 0' }}>
            {`Searches: ${payload[0].value}`}
          </p>
        </div>
      );
    }
    return null;
  };

  return (
    <div className="stat-card" style={{ gridColumn: 'span 2', height: '300px' }}>
      <h3>Search Activity (Last 7 Days)</h3>
      <div style={{ width: '100%', height: 'calc(100% - 30px)' }}>
        <ResponsiveContainer>
          <BarChart 
            data={data} 
            margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
          >
            <CartesianGrid 
              strokeDasharray="3 3" 
              stroke="#3d3d3d" 
              vertical={false} 
            />
            <XAxis 
              dataKey="date" 
              stroke="#a0a0a0"
              tick={{ fill: '#a0a0a0', fontSize: 12 }}
              tickLine={{ stroke: '#3d3d3d' }}
            />
            <YAxis 
              stroke="#a0a0a0"
              tick={{ fill: '#a0a0a0', fontSize: 12 }}
              tickLine={{ stroke: '#3d3d3d' }}
              axisLine={{ stroke: '#3d3d3d' }}
            />
            <Tooltip content={<CustomTooltip />} />
            <Bar 
              dataKey="searches" 
              fill="#10b981" 
              radius={[4, 4, 0, 0]}
              maxBarSize={50}
            />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
}

export default SearchActivityChart;